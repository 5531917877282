import { JobDetailShort } from "./Api";
import { JobTypeFilter, Role } from "./Utility";

export type FilterableJobProperty = {
  value: keyof Pick<
    JobDetailShort,
    | "jobDisplayId"
    | "jobType"
    | "jobSubType"
    | "callsource"
    | "circuit"
    | "address"
    | "aor"
    | "crewId"
    | "jobStatus"
    | "crewAssignmentStatus"
    | "outageDate"
    | "customerOut"
    | "etr"
    | "causeCode"
    | "deviceSubType"
    | "extent"
    | "callCode"
  >;
  label: string;
};

const jobIdFilter: FilterableJobProperty = {
  value: "jobDisplayId",
  label: "Job Number",
};
const jobTypeFilter: FilterableJobProperty = {
  value: "jobType",
  label: "Job Type",
};
const jobSubTypeFilter: FilterableJobProperty = {
  value: "jobSubType",
  label: "Job Sub Type",
};
const callSourceFilter: FilterableJobProperty = {
  value: "callsource",
  label: "Call Source",
};
export const circuitFilter: FilterableJobProperty = {
  value: "circuit",
  label: "Circuit",
};
const addressFilter: FilterableJobProperty = {
  value: "address",
  label: "Location",
};
const aorFilter: FilterableJobProperty = { value: "aor", label: "AOR" };
const crewIdFilter: FilterableJobProperty = {
  value: "crewId",
  label: "Crew ID",
};
const jobStatusFilter: FilterableJobProperty = {
  value: "jobStatus",
  label: "Job Status",
};
const crewAssignmentStatusFilter: FilterableJobProperty = {
  value: "crewAssignmentStatus",
  label: "Crew Assign.",
};
const outageDateFilter: FilterableJobProperty = {
  value: "outageDate",
  label: "Outage Date",
};
const customerOutFilter: FilterableJobProperty = {
  value: "customerOut",
  label: "Customer Out",
};
const etrFilter: FilterableJobProperty = {
  value: "etr",
  label: "ETR",
};

const causeCodeFilter: FilterableJobProperty = {
  value: "causeCode",
  label: "Cause Code",
};

const deviceTypeFilter: FilterableJobProperty = {
  value: "deviceSubType",
  label: "Device Type",
};

const extentFilter: FilterableJobProperty = {
  value: "extent",
  label: "Extent",
};

const callCodeFilter: FilterableJobProperty = {
  value: "callCode",
  label: "Call Code",
};

export const defaultJobPropertyFilters: FilterableJobProperty[] = [
  jobIdFilter,
  addressFilter,
  jobTypeFilter,
  jobSubTypeFilter,
  circuitFilter,
  crewIdFilter,
  crewAssignmentStatusFilter,
  etrFilter,
  causeCodeFilter,
  deviceTypeFilter,
  extentFilter,
  callCodeFilter,
];

export const treeTrimJobPropertyFilters: FilterableJobProperty[] = [
  jobIdFilter,
  addressFilter,
  jobTypeFilter,
  jobSubTypeFilter,
  circuitFilter,
  outageDateFilter,
  crewIdFilter,
  callSourceFilter,
  deviceTypeFilter,
  extentFilter,
  callCodeFilter,
];

export const damageAssessJobPropertyFilters: FilterableJobProperty[] = [
  jobIdFilter,
  addressFilter,
  jobTypeFilter,
  jobSubTypeFilter,
  circuitFilter,
  outageDateFilter,
  customerOutFilter,
  causeCodeFilter,
  deviceTypeFilter,
  extentFilter,
  callCodeFilter,
];

export const nonOutageJobPropertyFilters: FilterableJobProperty[] = [
  jobIdFilter,
  addressFilter,
  jobTypeFilter,
  jobSubTypeFilter,
  circuitFilter,
  outageDateFilter,
  crewIdFilter,
  crewAssignmentStatusFilter,
  etrFilter,
  causeCodeFilter,
  deviceTypeFilter,
  extentFilter,
  callCodeFilter,
];

export const outageJobPropertyFilters: FilterableJobProperty[] = [
  jobIdFilter,
  addressFilter,
  jobTypeFilter,
  jobSubTypeFilter,
  circuitFilter,
  crewIdFilter,
  crewAssignmentStatusFilter,
  customerOutFilter,
  etrFilter,
  causeCodeFilter,
  deviceTypeFilter,
  extentFilter,
  callCodeFilter,
];

/**
 * accepts a boolean showCrewJobs, an optional Role and the jobfilter
 * that returns the list of fields that represent the columns rendered and the options
 * in the drop down filter
 * @param showCrewJobs
 * @param jobFilter
 * @param role
 * @returns
 */
export const getFilterableJobProperties = (
  jobFilter: JobTypeFilter,
  role: Role | null
): FilterableJobProperty[] => {
  if (role && role.tag !== "employee") {
    if (role?.tt) {
      // this should be tree trim
      return treeTrimJobPropertyFilters;
    } else {
      // this should be defailt
      return defaultJobPropertyFilters;
    }
  } else {
    switch (jobFilter) {
      case "DamageAssess":
        return damageAssessJobPropertyFilters;
      case "NonOutage":
        return nonOutageJobPropertyFilters;
      case "Outage":
        return outageJobPropertyFilters;
      case "TreeTrim":
        return treeTrimJobPropertyFilters;
      default:
        return defaultJobPropertyFilters;
    }
  }
};
