import * as signalR from "@microsoft/signalr";
import { JobFilter, SingleComment, JobDetailCrewAssignment } from "./Api";

const MapServerURL = process.env.REACT_APP_MAP_URL;

export const arcgisBaseUrl = `${process.env.REACT_APP_API_URL}/esri/services`;
export const dteVehicleLayerPath = `${arcgisBaseUrl}/CentralDispatch/Service_Ops_And_Substations/MapServer`;
export const searchLayerPath = `${arcgisBaseUrl}/CentralDispatch/Service_Ops_And_Substations/MapServer/9`;
export const treeTrimTruckLayerPath = `${arcgisBaseUrl}/CentralDispatch/Tree_Trim_Trucks/MapServer`;
export const contractCrewTruckLayerPath = `${arcgisBaseUrl}/OutageStatusApp/Contract_Crew_Trucks/MapServer/0`;
export const electricFacilityLayerPath = `${arcgisBaseUrl}/OutageStatusApp/EdisonFacility/MapServer`;
// export const outageJobLayerURL = "http://outagemap.serv.dteenergy.com/GISRest/services/OMP/OutageLocations/MapServer/2";
export const outageJobLayerURL = `${process.env.REACT_APP_API_URL}/esri/arcgis/rest/services/OMP/OutageLocations/MapServer/2`;
export const contractCrewTruckLayerId = "Contract_Crew_Trucks";
export const dteVehicleLayerId = "DTE_Vehicles";
export const treeTrimTruckLayerId = "Tree_Trim_Trucks";
export const electricFacilitiesLayerId = "Electric_Facilities";
export const IS_SUPERVISOR = "isSupervisor";

export const CHIEF_KEY = "extension_a326dde48688470e84a7da80dceb2854_dtechief";

export const JOB_STATUS_NEW = "New";

export type Role = {
  tag: string;
  tt: boolean;
  jwt: string;
  crews: string[];
  foreman: boolean;
  supervisor: boolean;
  id: string;
  name: string;
  serviceCenter?: string;
  overrides?: {
    tag?: "employee" | "contractor";
    tt?: boolean;
    supervisor?: boolean;
  };
};

export const getMapLink = (lat: number | string, lng: number | string) => {
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    return `https://maps.apple.com/?daddr=${lat},${lng}`;
  } else {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  }
};

export const getSOSUrlForJob = (jobDisplayId: string) => {
  return `${process.env.REACT_APP_SOS_URL}/job/${jobDisplayId}?tab=map`;
};

export const getSOSUrlForJobCalls = (jobDisplayId: string) => {
  return `${process.env.REACT_APP_SOS_URL}/job/${jobDisplayId}?tab=calls`;
};

export const buildTokenUrl = (userId: string, password: string) =>
  `${MapServerURL}/arcgis/tokens/?request=gettoken&username=${userId}&password=${encodeURIComponent(
    password
  )}`;

export const buildLayerUrl = (url: string, token: string, subKey: string) =>
  `${url}/?access_token=${encodeURIComponent(
    token
  )}&subscription-key=${encodeURIComponent(subKey)}`;

export type ServiceCenter = {
  value: string;
  label: string;
  region: string;
  description: string;
  count: number;
};

export const YES = "YES";
export const NO = "NO";

export const serviceCenters: ServiceCenter[] = [
  {
    value: "LAP",
    label: "Lapeer",
    region: "T",
    description: "Lapeer",
    count: 0,
  },
  {
    value: "MAR",
    label: "Marysville",
    region: "T",
    description: "Marysville",
    count: 0,
  },
  {
    value: "MTC",
    label: "Mt Clemens",
    region: "T",
    description: "Mt Clemens",
    count: 0,
  },
  {
    value: "NAE",
    label: "North Area",
    region: "T",
    description: "North Area",
    count: 0,
  },
  {
    value: "HWL",
    label: "Howell",
    region: "N",
    description: "Howell",
    count: 0,
  },
  {
    value: "PON",
    label: "Pontiac",
    region: "N",
    description: "Pontiac",
    count: 0,
  },
  {
    value: "SBY",
    label: "Shelby",
    region: "N",
    description: "Shelby",
    count: 0,
  },
  {
    value: "CAN",
    label: "Caniff",
    region: "D",
    description: "Caniff",
    count: 0,
  },
  {
    value: "RFD",
    label: "Redford",
    region: "D",
    description: "Redford",
    count: 0,
  },
  {
    value: "TBY",
    label: "Trombly",
    region: "D",
    description: "Trombly",
    count: 0,
  },
  {
    value: "ANN",
    label: "Ann Arbor",
    region: "W",
    description: "Ann Arbor",
    count: 0,
  },
  {
    value: "NPT",
    label: "Newport",
    region: "W",
    description: "Newport",
    count: 0,
  },
  {
    value: "WWS",
    label: "Western Wayne",
    region: "W",
    description: "Western Wayne",
    count: 0,
  },
  {
    value: "TNA",
    label: "TNA",
    region: "",
    description: "",
    count: 0,
  },
];

export const CrewAssignmentStatus = [
  {
    value: "Acknowledged",
    label: "Acknowledged",
  },
  {
    value: "En-Route",
    label: "En-Route",
  },
  {
    value: "Arrived",
    label: "Arrived",
  },
];

export const causeCodes = [
  {
    value: "ANIMAL",
    label: "ANIMAL",
  },
  {
    value: "CUSTOMER EQUIPMENT",
    label: "CUSTOMER EQUIPMENT",
  },
  {
    value: "EQUIPMENT FAILURE",
    label: "EQUIPMENT FAILURE",
  },
  {
    value: "EQUIPMENT OVERLOAD",
    label: "EQUIPMENT OVERLOAD",
  },
  {
    value: "ICE",
    label: "ICE",
  },
  {
    value: "LIGHTNING",
    label: "LIGHTNING",
  },
  {
    value: "METER / BLOCKS",
    label: "METER / BLOCKS",
  },
  {
    value: "NO WORK DONE",
    label: "NO WORK DONE",
  },
  {
    value: "FOREIGN UTILITY EQUIPMENT",
    label: "FOREIGN UTILITY EQUIPMENT",
  },
  {
    value: "PLANNED WORK INTENTIONAL",
    label: "PLANNED WORK INTENTIONAL",
  },
  {
    value: "PUBLIC INTERFERENCE",
    label: "PUBLIC INTERFERENCE",
  },
  {
    value: "THEFT",
    label: "THEFT",
  },
  {
    value: "TREE MORE THAN 15FT FROM LINES",
    label: "TREE MORE THAN 15FT FROM LINES",
  },
  {
    value: "TREE LESS THAN 15FT FROM LINES",
    label: "TREE LESS THAN 15FT FROM LINES",
  },
  {
    value: "INTENTIONAL TO REPAIR DAMAGE",
    label: "INTENTIONAL TO REPAIR DAMAGE",
  },
  {
    value: "WIND",
    label: "WIND",
  },
];

export const CrewAssignmentStatuses = [
  {
    value: "THEFT",
    label: "THEFT",
  },
  {
    value: "TREEBROW",
    label: "TREEBROW",
  },
  {
    value: "TREEIROW",
    label: "TREEIROW",
  },
  {
    value: "TROUBLE INTENTIONAL",
    label: "TROUBLE INTENTIONAL",
  },
  {
    value: "WIND",
    label: "WIND",
  },
];

export const TTdispositionCodes = [
  {
    value: "CONFIRMED",
    label: "CONFIRMED",
  },
  {
    value: "MAINTENANCE",
    label: "MAINTENANCE",
  },
  {
    value: "C JOB",
    label: "C JOB",
  },
  {
    value: "TRIMMING BY CUST REQ",
    label: "TRIMMING BY CUST REQ",
  },
  {
    value: "RELEASED BY LINES",
    label: "RELEASED BY LINES",
  },
];

export const dispositionCodes = [
  {
    value: "WORK COMPLETE",
    label: "WORK COMPLETE",
  },
  {
    value: "INSIDE TROUBLE",
    label: "INSIDE TROUBLE",
  },
  {
    value: "OK ON ARRIVAL",
    label: "OK ON ARRIVAL",
  },
  {
    value: "FOREIGN WIRE",
    label: "FOREIGN WIRE",
  },
  {
    value: "CANT GET IN",
    label: "CANT GET IN",
  },
  {
    value: "SEND TO SCHEDULING",
    label: "SEND TO SCHEDULING",
  },
  {
    value: "RETURN TO ELECTRIC DISPATCH",
    label: "RETURN TO ELECTRIC DISPATCH",
  },
];

// TODO: This is no longer used - remove later
export const JobStatusOptions = [
  {
    value: "Acknowledged",
    label: "Acknowledged",
  },
  {
    value: "En-Route",
    label: "En-Route",
  },
  {
    value: "Arrived",
    label: "Arrived",
  },
  {
    value: "P",
    label: "P",
  },
];

export const closeableStatusCodes = ["In Progress"];
export const closeJobTypes = ["NON-OUTAGE", "FOLLOWUP"];

export const crewTypesNotAllowedToCloseOutages = [
  "TREE TRIM",
  "DAMAGE ASSESSMENT",
  "STORM SUPPORT",
];

export const materialAffectedList = [
  {
    value: "UNKNOWN",
    label: "UNKNOWN",
  },
  {
    value: "FUSE CUTOUT",
    label: "FUSE CUTOUT",
  },
  {
    value: "TRANSFORMER",
    label: "TRANSFORMER",
  },
  {
    value: "POLE",
    label: "POLE",
  },
  {
    value: "FUSE BLOCK",
    label: "FUSE BLOCK",
  },
  {
    value: "ARRESTER",
    label: "ARRESTER",
  },
  {
    value: "METER/RELAY/CONTROL",
    label: "METER / RELAY / CONTROL",
  },
  {
    value: "METER BASE",
    label: "METER BASE",
  },
  {
    value: "DISCONNECT",
    label: "DISCONNECT",
  },
  {
    value: "STRUCTURE",
    label: "STRUCTURE",
  },
  {
    value: "CONNECTOR BOLT ON",
    label: "CONNECTOR BOLT ON",
  },
  {
    value: "RECLOSER",
    label: "RECLOSER",
  },
  {
    value: "SECTIONALIZER",
    label: "SECTIONALIZER",
  },
  {
    value: "BREAKER",
    label: "BREAKER",
  },
  {
    value: "CONNECTOR SLEEVE",
    label: "CONNECTOR SLEEVE",
  },
  {
    value: "CONNECTOR SPLICE",
    label: "CONNECTOR SPLICE",
  },
  {
    value: "CONNECTOR JOINT",
    label: "CONNECTOR JOINT",
  },
  {
    value: "CABLE POLE",
    label: "CABLE POLE",
  },
  {
    value: "POT HEAD",
    label: "POT HEAD",
  },
  {
    value: "FAN OUT",
    label: "FAN OUT",
  },
  {
    value: "CONNECTOR HOT TAP",
    label: "CONNECTOR HOT TAP",
  },
  {
    value: "INSULATOR PIN",
    label: "INSULATOR PIN",
  },
  {
    value: "INSULATOR LP",
    label: "INSULATOR LP",
  },
  {
    value: "POLE TOP SWITCH",
    label: "POLE TOP SWITCH",
  },
  {
    value: "PRIMARY SWITCH CABINET SWITCH",
    label: "PRIMARY SWITCH CABINET SWITCH",
  },
  {
    value: "GROUND WIRE",
    label: "GROUND WIRE",
  },
  {
    value: "SHIELD WIRE",
    label: "SHIELD WIRE",
  },
  {
    value: "GUY WIRE",
    label: "GUY WIRE",
  },
  {
    value: "CAPACITOR",
    label: "CAPACITOR",
  },
  {
    value: "TERMINAL",
    label: "TERMINAL",
  },
  {
    value: "PEDESTAL",
    label: "PEDESTAL",
  },
  {
    value: "CONDUCTOR",
    label: "CONDUCTOR",
  },
  {
    value: "CABLE",
    label: "CABLE",
  },
  {
    value: "CROSS-ARM",
    label: "CROSS-ARM",
  },
  {
    value: "STANDOFF",
    label: "STANDOFF",
  },
  {
    value: "NO MATERIAL AFFECTED",
    label: "NO MATERIAL AFFECTED",
  },
];

export const FilteredCrewAssignStatus = ["Canceled", "Completed"];

export const FilteredJobStatus = ["Canceled", "Completed"];

export const CallCodesForEditSubType = [
  "SDXL",
  "TRAFCSIGPF",
  "WDPF",
  "MISCPF",
  "UGEXP",
  "PTFIRE",
  "PPWD",
  "STRUFIREPF",
  "PRIMARY",
  "SLO",
  "CONTACT",
  "TRAPPED",
  "METEREXG",
  "SDLTSOK",
  "COLLECT",
  "HIGHVOL",
  "INTERRUPTIBLE",
  "AMI_XCURR",
  "ONELEG",
  "EHS",
  "HIC",
  "HOSP",
  "HZ",
  "ARC",
  "LOWVOL",
  "BP",
  "OPL",
  "XCURR",
  "FLICKER",
  "SCHOOL",
  "PREFLT",
];

export const TTJobSubTypeOptions = [
  {
    value: "TT ONLY - CONFIRMED WORK",
    label: "TT-CONF, Pole-to-pole Line",
  },
  {
    value: "TT ONLY - BACKYARD BUCKET",
    label: "Backyard machine needed",
  },
  {
    value: "TT ONLY - BRUSH PICKUP",
    label: "Brush pickup only",
  },
  {
    value: "TT-OH MEET SAFE FOR FOLLOWUP",
    label: "TT needs OHL Non-Hazard, Safe For Follow-up",
  },
  {
    value: "PUBLIC SAFETY ESCALATION",
    label: "Escalation - Public Safety Concern",
  },
  {
    value: "TT ONLY - SERVICE",
    label: "TT-CONF - Service Line",
  },
];

export const defaultSybtypeOptions = [
  { value: "PUBLIC SAFETY ESCALATION", label: "PUBLIC SAFETY ESCALATION" },
  { value: "SERVICE - HOUSE", label: "SERVICE - HOUSE" },
  {
    value: "SERVICE - POLE NO TRANSFORMER",
    label: "SERVICE - POLE NO TRANSFORMER",
  },
  { value: "SERVICE - POLE TRANSFORMER", label: "SERVICE - POLE TRANSFORMER" },
  { value: "METER", label: "METER" },
  { value: "OPEN NEUTRAL", label: "OPEN NEUTRAL" },
  { value: "OPEN-LOOP-MAXIMO", label: "OPEN-LOOP-MAXIMO" },
  { value: "COLLECTION RESTORE AT POLE", label: "COLLECTION RESTORE AT POLE" },
  { value: "PRIMARY DOWN TAPED OFF", label: "PRIMARY DOWN TAPED OFF" },
  { value: "SECONDARY CUT IN THE CLEAR", label: "SECONDARY CUT IN THE CLEAR" },
  { value: "OHCONF - SECONDARY VOLTAGE", label: "OHCONF - SECONDARY VOLTAGE" },
  { value: "SECONDARY DOWN TAPED OFF", label: "SECONDARY DOWN TAPED OFF" },
  { value: "STANDBY", label: "STANDBY" },
  { value: "UGCONF", label: "UGCONF" },
  { value: "PATROL-SPECIAL", label: "PATROL-SPECIAL" },
  { value: "PATROL-EMERGENCY", label: "PATROL-EMERGENCY" },
];

export const yesNoOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

export const cancelOrCompletedJobStatuses = [
  "Canceled",
  "Completed",
  "Archived",
];

export type JobTypeFilter =
  | "All"
  | "Outage"
  | "TreeTrim"
  | "NonOutage"
  | "DamageAssess"
  | "StreetLight";

// TODO: investigate if it's still needed
export const defualtToOneCustOutJobs = [
  "ONELEG",
  "SDXL",
  "PRIMARY",
  "EHS",
  "HOSP",
  "HIC",
  "SCHOOL",
];

export const OutageJobs = [
  "OUTAGE",
  "PRIORITY",
  "PLANNED OUTAGE",
  "SERVICE DOWN NO LIGHTS",
];

export const ClosableOutageJobs = [
  "OUTAGE",
  "PRIORITY",
  "SERVICE DOWN NO LIGHTS",
];

export const RestorableOutageJobs = ["OUTAGE", "PLANNED OUTAGE"];
export const RestorableEnergizationStatus = ["VERIFIED", "PREDICTED"];

export const TreeTrimJobs = ["TREE TRIM"];

export const NonOutageJobs = [
  "NON-OUTAGE",
  "PF-WD-UGEXP",
  "EMERGENCY",
  "METER EXCHANGE",
  "HIGH VOLTAGE",
  "OPERATE",
  "PATROL",
  "4.8 KV GROUND",
];

export const DamageAssessmentJobs = ["DAMAGE ASSESSMENT"];

export const StreetLightJobs = ["STREETLIGHT"];

export const AllJobs = [
  ...OutageJobs,
  ...TreeTrimJobs,
  ...NonOutageJobs,
  ...DamageAssessmentJobs,
  ...StreetLightJobs,
];

export const JobTypeOptions: { label: string; value: JobTypeFilter }[] = [
  { label: "All Jobs", value: "All" },
  { label: "Outage Jobs", value: "Outage" },
  { label: "Tree Trim Jobs", value: "TreeTrim" },
  { label: "Non-Outage Jobs", value: "NonOutage" },
  { label: "Damage Assessment Jobs", value: "DamageAssess" },
  { label: "Street Light Jobs", value: "StreetLight" },
];

type AllServiceCenterType = "All";
export const ALL_SERVICE_CENTER: AllServiceCenterType = "All";

export type ServiceCenterSelection = ServiceCenter[] | AllServiceCenterType;

export const permissionsCheck = (
  userCrews: string[] | undefined,
  jobCrews: JobDetailCrewAssignment[] | undefined,
  allowedRoles: string[]
): boolean => {
  if (
    userCrews &&
    userCrews.length > 0 &&
    jobCrews &&
    jobCrews.length > 0 &&
    allowedRoles &&
    allowedRoles.length > 0
  ) {
    allowedRoles = allowedRoles.map((r) => r.toUpperCase());
    return userCrews.some((uc) =>
      jobCrews.some(
        (jc) =>
          jc.crewId &&
          jc.crewId.toUpperCase() === uc.toUpperCase() &&
          jc.crewAssignmentStatus !== "Canceled" &&
          jc.crewAssignmentStatus !== "Completed" &&
          allowedRoles.includes(jc.crewType.toUpperCase())
      )
    );
  }
  return false;
};

export const buildJobFilterWithCrewIds = (
  filterType: JobTypeFilter,
  aor: ServiceCenterSelection,
  includeFollowUpJobs: boolean,
  crewIds: string[]
): JobFilter => {
  let jobFilter = buildJobFilter(filterType, aor, includeFollowUpJobs);
  if (crewIds?.length > 0) {
    jobFilter.crewId = crewIds.join(",");
  }
  return jobFilter;
};

export const buildJobFilter = (
  filterType: JobTypeFilter,
  aor: ServiceCenterSelection,
  includeFollowUpJobs: boolean
): JobFilter => {
  let jobFilter: JobFilter;

  if (aor === ALL_SERVICE_CENTER || aor.length === 0) {
    switch (filterType) {
      case "All":
        jobFilter = { jobType: AllJobs };
        break;
      case "Outage":
        jobFilter = { jobType: OutageJobs };
        break;
      case "TreeTrim":
        jobFilter = { jobType: TreeTrimJobs };
        break;
      case "NonOutage":
        jobFilter = { jobType: NonOutageJobs };
        break;
      case "DamageAssess":
        jobFilter = { jobType: DamageAssessmentJobs };
        break;
      case "StreetLight":
        jobFilter = { jobType: StreetLightJobs };
        break;
    }
  } else {
    const aors = aor.map((x) => x.value);
    switch (filterType) {
      case "All":
        jobFilter = { jobType: AllJobs, aor: aors };
        break;
      case "Outage":
        jobFilter = { jobType: OutageJobs, aor: aors };
        break;
      case "TreeTrim":
        jobFilter = { jobType: TreeTrimJobs, aor: aors };
        break;
      case "NonOutage":
        jobFilter = { jobType: NonOutageJobs, aor: aors };
        break;
      case "DamageAssess":
        jobFilter = { jobType: DamageAssessmentJobs, aor: aors };
        break;
      case "StreetLight":
        jobFilter = { jobType: StreetLightJobs, aor: aors };
        break;
    }
  }

  if (jobFilter.jobType) {
    if (includeFollowUpJobs) {
      if (!jobFilter.jobType.includes("FOLLOWUP")) {
        jobFilter.jobType.push("FOLLOWUP");
      }
    } else {
      jobFilter.jobType = jobFilter.jobType.filter((f) => f !== "FOLLOWUP");
    }
  }

  return jobFilter;
};

/**
 * This chunk of code was provided and cleaned up slightly from the client as a means to translate
 * what we call a circuit id (feederid for them) into path segments to retrieve these files.
 * Combine numeric part and chr part of the code
 * Remove if the pdf name has leading 0
 * example i/p-FDR:PHENX9804, o/p-9804PHENX.pdf
 * Subtransmission Operating Map
 * SOC One Line Diagrams
 * Subtransmission Operating Map
 * Subtransmission Operating Map
 * SOC One Line Diagrams
 * @param feederID
 * @returns
 */
export const oneLinesPDFUrl = (feederID: string): string => {
  const pdfPathSolutions = [];

  // check if FEEDERID starts with PSW
  const regexPSW = /^PSW\d+/g;

  if (regexPSW.test(feederID)) {
    pdfPathSolutions.push(`Drawing-Primary Services Schematics/${feederID}`);
  }

  // check FEEDERID TIE & TRK
  // Example TIE 1224A-B Expected TIE1224
  // FDR:TRK 1135A-S Expected TRK1135
  const regexTRKTIE = /^(TRK|TIE) \d+/g;

  if (regexTRKTIE.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-Subtransmission Operating Map/${feederID
        .match(regexTRKTIE)
        ?.join("")
        .replace(/ /g, "")}`
    );
  }

  // check if FEEDERID has WP (Wind Park) follows below pattern
  const regexWP = /[A-Z]{8}\d+/;
  const regexWP2 = /WPWTG/;

  if (regexWP.test(feederID) && regexWP2.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-SOC One Line Diagrams/${feederID.replace("WPWTG", "WP WTG")}`
    );
  }

  // check if FEEDERID has SP (Solar Park) follows below pattern
  const regexSP = /[A-Z]{7}\d+/;
  const regexSP2 = /SPSU/;

  if (regexSP.test(feederID) && regexSP2.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-SOC One Line Diagrams/${feederID.replace("SPSU", "SP SU")}`
    );
  }

  // Distrebution Feeders
  // [A-Z ]{5}\d{4}
  const regexDistrebution = /^[A-Z ]{5}\d{4}/g;

  if (regexDistrebution.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-DC Operating Map/${feederID
        .match(/[0-9]/g)
        ?.join("")
        .replace(/^0+/, "")}${feederID.match(/[a-zA-Z]/g)?.join("")}`
    );
  }

  if (pdfPathSolutions.length === 0) {
    return feederID;
  } else if (pdfPathSolutions.length === 1) {
    return encodeURI(`${pdfPathSolutions.join("")}.pdf`);
  } else if (pdfPathSolutions.length > 1) {
    console.warn(`${feederID} Failed Regex. Multiple matches found !! `);

    console.log(pdfPathSolutions);

    // this is supposed to be a failure case, the old code we brought over used to just return the id at this point.
    // im just using empty string to indicate failure for now.
    return "";
  }

  return "";
};

export const dateFormat: any = [
  "en-US",
  { dateStyle: "short", timeStyle: "short" },
];

export const isValidDate = (date: string) => {
  const parsed = Date.parse(date);
  if (isNaN(parsed) || parsed === null || parsed === undefined) {
    return false;
  } else {
    return true;
  }
};

export const MS_HOURS = 3600000 / 2;
export const availableCrewStatuses = ["AV", "AQ", "AP", "AF", "AM", "EO", "1R"];

// const crewStatusLabels = [
//   {
//     label: "Available",
//     value: "AV",
//   },
//   {
//     label: "AvInQuarters",
//     value: "AQ",
//   },
//   {
//     label: "AvOnPager",
//     value: "AP",
//   },
//   {
//     label: "AvOnFoot",
//     value: "AF",
//   },
//   {
//     label: "AvOnMdt",
//     value: "AM",
//   },
//   {
//     label: "AvEmergOnly",
//     value: "EO",
//   },
//   {
//     label: "AvFstRspndr",
//     value: "1R",
//   },
//   {
//     label: "Dispatched",
//     value: "DP",
//   },
//   {
//     label: "Enroute",
//     value: "ER",
//   },
//   {
//     label: "Arrive",
//     value: "AR",
//   },
//   {
//     label: "ArriveDanger",
//     value: "AD",
//   },
//   {
//     label: "LongArrive",
//     value: "LA",
//   },
//   {
//     label: "Service",
//     value: "OS",
//   },
//   {
//     label: "Transport",
//     value: "TR",
//   },
//   {
//     label: "TransportArrive",
//     value: "TA",
//   },
//   {
//     label: "Acknowledge",
//     value: "AK",
//   },
//   {
//     label: "GroupMember",
//     value: "GP",
//   },
//   {
//     label: "PendingAV",
//     value: "PA",
//   },
//   {
//     label: "PendingMobile",
//     value: "PM",
//   },
//   {
//     label: "ReliefReq",
//     value: "RL",
//   },
//   {
//     label: "OnePSNeeded",
//     value: "P1",
//   },
//   {
//     label: "TwoPSNeeded",
//     value: "P2",
//   },
//   {
//     label: "FieldSup",
//     value: "FS",
//   },
//   {
//     label: "HelpRequested",
//     value: "HR",
//   },
//   {
//     label: "Relief Sent",
//     value: "RS",
//   },
//   {
//     label: "Dispatch Assigned",
//     value: "DA",
//   },
// ];

// export const crewStatusValueToLabel = (status: string) => {
//   const label = crewStatusLabels.find((x) => x.value === status)?.label;
//   if (label) {
//     return label;
//   } else {
//     return status;
//   }
// };

export const sortCommentsByDate = (a: SingleComment, b: SingleComment) => {
  if (isValidDate(a.timestamp) && isValidDate(b.timestamp)) {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  } else if (isValidDate(a.timestamp)) {
    return -1;
  } else if (isValidDate(b.timestamp)) {
    return 1;
  } else {
    return 1;
  }
};

export const getFromLocalStorageOrDefault = <T>(
  key: string,
  defaultValue: T,
  validValues?: T[]
): T => {
  const value = localStorage.getItem(key);
  if (value) {
    try {
      const parsedValue = JSON.parse(value);
      if (validValues) {
        if (validValues?.includes(parsedValue)) {
          return parsedValue as T;
        } else {
          return defaultValue;
        }
      } else {
        return parsedValue as T;
      }
    } catch {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};

export const pushToLocalStorageList = <T>(key: string, item: T) => {
  const list: T[] = getFromLocalStorageOrDefault(key, []);
  list.push(item);
  localStorage.setItem(key, JSON.stringify(list));
};

export const removeByIdFromLocalStorageList = (
  key: string,
  id: string | number
) => {
  const list: { id: string | number }[] = getFromLocalStorageOrDefault(key, []);
  const newList = list.filter((x) => x.id !== id);
  localStorage.setItem(key, JSON.stringify(newList));
};

export const genId = () => new Date().getTime().toString(36);

export const getDefaultSignalROptions = (accessToken: string) => {
  const transport = signalR.HttpTransportType.WebSockets;
  const options = {
    transport,
    logMessageContent: false,
    logger: signalR.LogLevel.Error,
    accessTokenFactory: () => accessToken,
  };

  return options;
};

export const getYesNoRadioOptionValue = (
  value: boolean | null
): string | null => {
  if (value === null) {
    return null;
  } else if (value) {
    return "Yes";
  } else {
    return "No";
  }
};

export const MAXIMO_MAX_LENGTH_DESC = 80;
export const MAXIMIO_MAX_LENGTH_LONG_DESC = 32_000;
export const MAXIMO_USER_ID = "OSA_MAXIMO_APPID";
