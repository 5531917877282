import React, { useState } from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as Util from "../../lib/Utility";
import {
  JobDetail,
  JobDetailShort,
  JobUpdateReq,
  updateJob,
} from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import { trackLocationData } from "../../lib/api/trackLocationApi";

const CauseCodeModal = (props: {
  job: JobDetail | JobDetailShort;
  jobDetails?: JobDetail;
  afterSubmit: Function;
}) => {
  const [selectedCauseCode, setSelectedCauseCode] = useState(
    props.job.causeCode
  );
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const handleSubmit = async () => {
    const req: JobUpdateReq = {
      msgId: props.job.jobId,
      causeCode: {
        causeCode: selectedCauseCode ?? "",
        comments: `${
          role?.name ?? ""
        } updated Cause Code to ${selectedCauseCode}`,
      },
    };
    dispatch(setLoading(true));
    if (selectedCauseCode !== "") {
      await trackLocationData("updateJob", props.job.jobId, role!);
      const res = await updateJob(props.job.jobId, req);
      if (res.tag === "ok") {
        await props.afterSubmit();
      } else {
        alert(
          "Error updating cause code, check your internet connection. You can resend the request through the Drafts page"
        );
        dispatch(setLoading(false));
      }
    }
  };

  const label =
    props.job.causeCode === "" || props.job.causeCode === undefined
      ? "??"
      : props.job.causeCode;

  return (
    <>
      {Util.permissionsCheck(role?.crews, props.jobDetails?.crew, [
        "OH",
        "UG",
        "CONTRACT",
        "FOREIGN",
        "EFO",
        "EMJ",
        "COMMUNITY LIGHTING",
      ]) ? (
        <Popup
          closeOnDocumentClick={true}
          modal={true}
          overlayStyle={{ cursor: "default" }}
          contentStyle={{ cursor: "default" }}
          className="responsive-modal"
          trigger={
            <div
              className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer ${
                label === "??" ? "text-red-600" : "text-green-600"
              }`}
            >
              {label}
              <FontAwesomeIcon className="ml-1" icon={faEdit} />
            </div>
          }
        >
          {(close: () => void) => (
            <div className="text-black py-1">
              <p className="mb-1">Select the Cause Code</p>
              <div className="grid gap-1 grid-cols-2 px-0.5">
                {Util.causeCodes.map((x) => (
                  <button
                    className={`${
                      selectedCauseCode === x.value
                        ? "bg-white border border-dte-500 text-dte-500"
                        : "border border-dte-500 bg-dte-500 text-white"
                    } mx-0.5 my-0.5 py-0.5 px-0.75`}
                    onClick={() => setSelectedCauseCode(x.value)}
                    key={x.value}
                  >
                    {x.label}
                  </button>
                ))}
              </div>
              <div className="w-full inline-flex flex-no-wrap justify-center my-1">
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                  onClick={() => {
                    console.log("send new cause code to server");
                    handleSubmit();
                    close();
                  }}
                >
                  Submit
                </button>
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                  onClick={() => {
                    setSelectedCauseCode(props.job.causeCode);
                    close();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Popup>
      ) : (
        <div
          className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full  ${
            label === "??" ? "text-red-600" : "text-green-600"
          }`}
        >
          {label}
        </div>
      )}
    </>
  );
};

export default CauseCodeModal;
