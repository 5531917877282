import http from "./Http";
import Axios, { AxiosRequestConfig } from "axios";
import msalInstance, { renewRequest } from "../auth/authProvider";
import * as Util from "./Utility";
import Resizer from "react-image-file-resizer";
import { getPosition } from "./api/trackLocationApi";

Axios.interceptors.request.use(async (req) => {
  try {
    const tokenRes = await msalInstance.acquireTokenSilent(renewRequest);
    req.headers.authorization = `Bearer ${tokenRes.idToken.rawIdToken}`;
  } catch (err) {
    const loginRes = await msalInstance.acquireTokenPopup(renewRequest);
    req.headers.authorization = `Bearer ${loginRes.idToken.rawIdToken}`;
  } finally {
    return req;
  }
});

const headers = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
  },
};

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ADMS_ROUTE}`,
  ...headers,
};

export const assetManagementConfig: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/assetmanagement`,
  ...headers,
};

export interface CosmosCrewMember {
  _id: string;
  id: string;
  name: string;
  email: string;
  smsReceivers: SMSReceivers;
  org: string;
  crew: string[];
  serviceCenter: any[];
  options: UserOptions;
  auth: UserAuth;
  active: boolean;
  createdBy: UserCreatedBy;
  createdAt: number;
}

export interface SDAPriorityLists {
  priority1: string[];
  priority2: string[];
}

export interface UserAuth {
  MSSTS: Mssts;
}

export interface Mssts {
  localAccountId: null;
  name: null;
  username: null;
}

export interface UserCreatedBy {
  id: string;
  name: string;
  org: string;
  email: string;
}

export interface UserOptions {
  tag: string;
  supervisor: boolean;
  treeTrim: boolean;
  damageAssessment: boolean;
}

export interface SMSReceivers {
  countryCode: string;
  phoneNumber: null;
}

interface Dimensions {
  height: number;
  width: number;
}

export const getUserDetails = async (id: string) => {
  const res = await http.get<CosmosCrewMember | null>(
    `${process.env.REACT_APP_ADMIN_URL}/api/crewMemberGet?code=${process.env.REACT_APP_ADMIN_API_KEY}`,
    {
      params: {
        id: id.toUpperCase(),
      },
      ...headers,
    }
  );
  return res;
};

export const getSDAPriorityList = async () => {
  const res = await http.get<SDAPriorityLists | null>(
    `${process.env.REACT_APP_ADMIN_URL}/api/configsGet?code=${process.env.REACT_APP_ADMIN_API_KEY}`,
    {
      params: {
        id: "sda-priority-list",
      },
      ...headers,
    }
  );
  return res;
};

export const getSDAJob = async () => {
  const jobSubTypes = ["UNCON", "UNCON-ALARM"].map((x) =>
    encodeURIComponent(x)
  );
  const jobStatus = ["New", "Pending", "PC3 Verified"].map((x) =>
    encodeURIComponent(x)
  );
  const jobType = [
    "OUTAGE",
    "PF-WD-UGEXP",
    "NON-OUTAGE",
    "SERVICE DOWN NO LIGHTS",
  ].map((x) => encodeURIComponent(x));
  const url = `/chatbotapi/GetNearestJob`;
  let queryString = "";

  let pos: GeolocationPosition = {
    coords: {
      accuracy: 0,
      altitude: 0,
      altitudeAccuracy: 0,
      heading: 0,
      latitude: 0,
      longitude: 0,
      speed: 0,
    },
    timestamp: new Date().getDate(),
  };

  try {
    pos = await getPosition();
  } catch (e) {
    console.error(e);
  }

  queryString += `?lat=${pos.coords.latitude}&long=${pos.coords.longitude}&jobState=A&activeCrewAssignment=N`;
  if (jobSubTypes && jobSubTypes.length > 0) {
    queryString += "&jobSubType=" + jobSubTypes.toString();
  }
  if (jobStatus && jobStatus.length > 0) {
    // spelling
    queryString += "&jobStatus=" + jobStatus.toString();
  }
  if (jobType && jobType.length > 0) {
    queryString += "&jobType=" + jobType.toString();
  }

  const priorityList = await getSDAPriorityList();
  if (priorityList.tag === "ok") {
    let priority1call;
    let priority2call;
    if (
      priorityList.data?.priority1 &&
      priorityList.data.priority1.length > 0
    ) {
      priority1call =
        queryString +
        "&callCode=" +
        priorityList.data.priority1
          .map((x) => encodeURIComponent(x))
          .toString();
    } else {
      // we don't have priority1 list, will use priority 2
      priority2call =
        queryString +
        "&callCode=" +
        priorityList.data?.priority2
          .map((x) => encodeURIComponent(x))
          .toString();
    }

    if (priority1call) {
      const res = await http.get<JobDetailShort>(url + priority1call, config);
      if (res.tag === "ok") {
        if (Array.isArray(res.data)) {
          // Got empty list, call priority 2 if available
          // check if we have items in priority2, if no - no jobs available
          if (
            priorityList.data?.priority2 &&
            priorityList.data.priority2.length > 0
          ) {
            priority2call =
              queryString +
              "&callCode=" +
              priorityList.data.priority2
                .map((x) => encodeURIComponent(x))
                .toString();
            const res2 = await http.get<JobDetailShort>(
              url + priority2call,
              config
            );
            if (res2.tag === "ok") {
              if (Array.isArray(res2.data)) {
                alert("No jobs available, please contact dispatch.");
              }
              return res2;
            } else {
              alert(
                "An error has occurred in getting priority 2 jobs, please try again."
              );
              return res2;
            }
          } else {
            alert("No jobs available, please contact dispatch.");
            return res;
          }
        } else {
          // got not empty list, we are good
          return res;
        }
      } else {
        alert(
          "An error has occurred in getting priority 1 jobs, please try again."
        );
        return res;
      }
    } else {
      const res = await http.get<JobDetailShort>(url + priority2call, config);
      if (res.tag === "ok") {
        if (Array.isArray(res.data)) {
          alert("No jobs available, please contact dispatch.");
        }
        return res;
      } else {
        alert(
          "An error has occurred in getting priority 2 jobs, please try again."
        );
        return res;
      }
    }
  } else {
    alert("An error has occurred getting priority jobs, please try again.");
  }
  return priorityList;
};

export const markNotificationsAsReadForUser = async (userId: string) => {
  const query = {
    collection: "Notifications",
    filter: { userId: userId.toUpperCase() },
    update: {
      $set: { read: true },
    },
    confirm: true,
  };

  const res = await http.post<{ message: string }>(
    `${process.env.REACT_APP_ADMIN_URL}/api/bulkUpdate?code=${process.env.REACT_APP_ADMIN_API_KEY}`,
    query,
    headers
  );
  return res;
};

const resizeFile = (image: File, dimensions: Dimensions) => {
  const scale = image.size / (15 * 1024 * 1024);
  return new Promise<string | Blob | File | ProgressEvent<FileReader>>(
    (resolve) => {
      Resizer.imageFileResizer(
        image,
        Math.floor(dimensions.width / scale),
        Math.floor(dimensions.height / scale),
        "PNG",
        100,
        0,
        (uri) => resolve(uri),
        "file"
      );
    }
  );
};

const getHeightAndWidthFromDataUrl = (dataURL: string) =>
  new Promise<Dimensions>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

export const uploadImage = async (image: File, pdf?: boolean) => {
  if (!pdf) {
    // if not pdf, maybe we need to resize image
    if (image.size > 10 * 1024 * 1024) {
      // if image over 10MB
      image = await getHeightAndWidthFromDataUrl(
        window.URL.createObjectURL(image)
      ).then(async (dimensions) => {
        const img = await resizeFile(image, dimensions);
        if (img instanceof File) {
          return img;
        } else {
          return image;
        }
      });
    }
  }
  const data = new FormData();
  data.append("file", image);
  const res = await http.post<{ message: string }>(
    `${process.env.REACT_APP_API_URL ?? ""}/images/upload`,
    data,
    {
      ...headers,
      timeout: 3 * 60000,
    }
  );
  return res;
};

export const getEmployeeDetail = async (id: string) => {
  const url = `/CrewMember/${encodeURIComponent(id)}`;
  const res = await http.get<GetEmployeeDetailRes>(url, config);
  return res;
};

export type GetEmployeeDetailRes = {
  employeeId: string;
  crewId: string;
  phoneNumber: string;
  name: string;
  crewStatus: string;
};

export const crewLogOn = async (crew: string) => {
  const url = `/Crew/${encodeURIComponent(crew)}/Logon`;
  const res = await http.patch(url, {}, config);
  return res;
};

export const crewLogOff = async (crew: string) => {
  const url = `/Crew/${encodeURIComponent(crew)}/Logoff`;
  const res = await http.patch(url, {}, config);
  return res;
};

export type CrewStatusByCrewList = {
  jobId: string;
  crewId: string;
  crewStatus: string;
  crewType: string;
  LogonStatus: "Y" | "N";
  errorCode: string;
  errorDescription: string;
};

export const getCrewStatusByCrewList = async (crewIds: string[]) => {
  const url = `/Status?crewIds=${encodeURIComponent(crewIds.join())}`;

  const res = await http.get<CrewStatusByCrewList[]>(url, {
    ...config,
  });
  return res;
};

export type CrewStatusUpdateReq = {
  msgId: string;
  jobId: string;
  workType: string;
  action: string;
};

export const updateCrewStatus = async (
  crew: string,
  update: CrewStatusUpdateReq
) => {
  const url = `/Crew/${encodeURIComponent(crew)}/Status`;
  const res = await http.patch(url, update, config);
  return res;
};

export type CrewAssignmentStatusUpdateReq = {
  msgId: string;
  jobId: string;
  comments: string;
  crewAssignmentStatus: string;
  eta?: string;
  disposition?: string[];
};

export const updateCrewAssignmentStatus = async (
  crew: string,
  update: CrewAssignmentStatusUpdateReq
) => {
  const url = `/Crew/${encodeURIComponent(crew)}/CrewAssignment/Status`;
  const res = await http.patch(url, update, config);
  return res;
};

export type JobDetailCrewAssignment = {
  crewId: string;
  crewAssignmentStatus: string;
  crewType: string;
  destination: string;
  latitude: string;
  longitude: string;
  workType: string;
};

export type JobType =
  | "OUTAGE"
  | "PRIORITY"
  | "PLANNED OUTAGE"
  | "SERVICE DOWN NO LIGHTS"
  | "TREE TRIM"
  | "NON-OUTAGE"
  | "PF-WD-UGEXP"
  | "EMERGENCY"
  | "METER EXCHANGE"
  | "METER DISCONNECT - RECONNECT"
  | "HIGH VOLTAGE"
  | "OPERATE"
  | "PATROL"
  | "4.8 KV GROUND"
  | "DAMAGE ASSESSMENT";

export type JobDetail = {
  jobId: string;
  jobDisplayId: string;
  callSource: string;
  jobType: string;
  jobSubType: string;
  causeCode?: string;
  aor: string;
  jobStatus: string;
  circuit: string;
  nominalCircuit?: string;
  etr: string;
  premiseId: string;
  customerOut: string | number;
  region: string;
  outageDate: string;
  crewAssignmentStatus: string;
  crewId: string;
  crew: JobDetailCrewAssignment[];
  longitude: string;
  latitude: string;
  address: string;
  phoneNumber: string;
  name: string;
  numOfCalls: string;
  prn_flag: string;
  xCord: string;
  yCord: string;
  deviceType: string;
  extent: string;
  callCode: string;
  meterId: string;
  comments: Comments;
  energizationStatus?: string;
};

export type Comments =
  | { comments: SingleComment[] | SingleComment }
  | SingleComment;

export type SingleComment = {
  timestamp: string;
  comment: string;
};

export const getJobDetailById = async (job: string) => {
  const url = `/Job/${encodeURIComponent(job)}`;
  const res = await http.get<JobDetail>(url, config);
  if (res.tag === "ok") {
    if (res.data.customerOut !== "") {
      res.data.customerOut = Number(res.data.customerOut);
    }
    if (
      Util.defualtToOneCustOutJobs.includes(res.data.jobType) &&
      !(Number(res.data.customerOut) > 0)
    ) {
      res.data.customerOut = 1;
    }
  }
  return res;
};

export const restoreJob = async (jobID: string) => {
  let msg = {
    messageID: jobID,
    jobId: jobID,
    restoreVerify: "restore",
    timesStamp: new Date().toISOString(),
  };
  const url = `/JobRestoreVerify`;
  const res = await http.post<JobRestoreResponse>(url, msg, config);
  return res;
};

export const setCrewAvailabilityStatus = async (
  status: string,
  crew: string,
  long: number,
  lat: number
) => {
  const params = {
    msgId: crypto.randomUUID(),
    crewStatus: status,
    longitude: long,
    latitude: lat,
  };
  const url = `/CrewAvailabilityUpdate/${crew}`;
  const res = await http.post<JobListByCrew>(url, params, config);
  if (res.tag !== "ok") {
    alert("Failed to send crew availability update!");
  }
};

export const getCircuitMap = async (pathSegments: string) => {
  const url = `${process.env.REACT_APP_API_URL}/circuitmap/${pathSegments}`;
  return await http.get<Blob>(url, {
    ...headers,
    responseType: "blob",
  });
};

export interface JobListByCrewAssignment {
  jobList: JobCrewAssignment[] | JobCrewAssignment;
}

export interface JobCrewAssignment {
  jobId: string;
  crewAssignments: CrewAssignment[] | CrewAssignment;
}

export interface CrewAssignment {
  crewId: string;
  name: string;
  crewAssignmentStatus: string;
  eta: string;
}

export const getCrewAssignmentByJobId = async (jobs: string[]) => {
  const params = { jobIds: jobs.join(",") };
  const url = `/Job/CrewAssignment`;
  const res = await http.get<{ job: JobListByCrewAssignment }>(url, {
    ...config,
    params,
  });
  return res;
};

export const getJobDetailByCrewList = async (crews: string[]) => {
  return getJobDetailByCrewListAndSC(crews, []);
};

export const getJobDetailByCrewListAndSC = async (
  crews: string[],
  aors: Util.ServiceCenterSelection
) => {
  let params = {};
  if (aors === Util.ALL_SERVICE_CENTER || aors.length === 0) {
    params = {
      crewIds: crews.join(","),
    };
  } else {
    params = {
      crewIds: crews.join(","),
      aor: aors.map((x) => x.value).join(","),
    };
  }

  const url = `/odw/jobFilters`;
  const res = await http.post<JobListByCrew>(url, params, config);
  if (res.tag === "ok") {
    if (Array.isArray(res.data)) {
      res.data.forEach((x) => {
        if (!("errorCode" in x) && "jobId" in x) {
          if (x.customerOut !== "") {
            x.customerOut = Number(x.customerOut);
          }
          if (
            Util.defualtToOneCustOutJobs.includes(x.jobType) &&
            !(Number(x.customerOut) > 0)
          ) {
            x.customerOut = 1;
          }
        }
      });
    }
  }

  return res;
};

export const convertJobByCrewToJobDetailShort = (
  job: JobByCrew
): JobDetailShort => {
  const converted: JobDetailShort = {
    jobId: job.jobId,
    jobDisplayId: job.jobDisplayId,
    jobType: job.jobType,
    jobSubType: job.jobSubType,
    outageDate: job.outageDate,
    callsource: job.callSource,
    causeCode: job.causeCode,
    etr: job.etr,
    circuit: job.circuit,
    customerOut: job.customerOut,
    address: job.address,
    longitude: job.longitude,
    latitude: job.latitude,
    aor: job.aor ?? "",
    crewAssignmentStatus: job.crewAssignmentStatus,
    jobStatus: job.jobStatus,
    crewId: job.crewId,
    callCode: job.callCode,
    deviceType: job.deviceType,
    deviceSubType: job.deviceSubType,
    extent: job.extent,
  };
  return converted;
};

export type JobByCrewErr = {
  errorCode: string;
  errorDescription: string;
  crewId: string;
};

export type JobListByCrew = (JobByCrew | JobByCrewErr)[];

export type JobRestoreResponse = {
  jobId: string;
  messageID: string;
  restoreVerify: string;
  errorCode?: string;
  errorDescription?: string;
};

export type JobByCrew = {
  jobId: string;
  jobDisplayId: string;
  jobType: string;
  jobSubType: string;
  outageDate: string;
  callSource: string;
  causeCode: string;
  etr: string;
  circuit: string;
  customerOut: string | number;
  address: string;
  longitude: string;
  latitude: string;
  crewId: string;
  aor: string;
  jobStatus: string;
  crewAssignmentStatus: string;
  deviceType: string;
  deviceSubType: string;
  extent: string;
  callCode: string;
};

export type JobFilter = {
  jobType?: string[];
  aor?: string[];
  crewId?: string;
};

export type JobDetailShort = {
  jobId: string;
  jobDisplayId: string;
  jobType: string;
  jobSubType: string;
  outageDate: string;
  callsource?: string;
  causeCode: string;
  etr: string;
  circuit: string | null;
  customerOut: string | number;
  address: string;
  longitude: string;
  latitude: string;
  aor: string;
  crewId: string;
  jobStatus: string;
  crewAssignmentStatus: string;
  deviceType: string;
  deviceSubType: string;
  extent: string;
  callCode: string;
};

export const getJobDetailByFilter = async (filter: JobFilter) => {
  const url = `/odw/jobFilters`;
  let queryString = {};

  if (filter && filter.jobType && filter.jobType.length > 0) {
    queryString = { ...queryString, jobType: filter.jobType.toString() };
  }

  if (filter && filter.aor) {
    queryString = { ...queryString, aor: filter.aor.join() };
  }

  if (filter && filter.crewId) {
    queryString = { ...queryString, crewIds: filter.crewId };
  }

  const res = await http.post<JobDetailShort[]>(url, queryString, config);
  if (res.tag === "ok" && (res.data as unknown) !== "") {
    res.data.forEach((x) => {
      if (x.customerOut !== "") {
        x.customerOut = Number(x.customerOut);
      }
      if (
        Util.defualtToOneCustOutJobs.includes(x.jobType) &&
        !(Number(x.customerOut) > 0)
      ) {
        x.customerOut = 1;
      }
    });
  }
  return res;
};

export type JobUpdateReq = {
  msgId?: string;
  etr?: Etr;
  causeCode?: CauseCode;
  jobType?: UpdateJobType;
  jobSubType?: UpdateJobSubType;
  jobStatus?: JobStatus;
  crewAssignmentStatus?: CrewAssignmentStatus;
  photo?: string;
  jobComments?: string;
  materialAffected?: string[];
};

export type CauseCode = {
  causeCode: string;
  comments: string;
};

export type Etr = {
  etr: string | null;
  comments: string;
};

export type CrewAssignmentStatus = {
  crewAssignmentStatus: string;
  comments: string;
};

export type JobStatus = {
  jobStatus: string;
  comments: string;
};

export type UpdateJobType = {
  jobType: string;
  comments: string;
};

export type UpdateJobSubType = {
  jobSubType: string;
  jobType: string;
  comments: string;
};

export type JobUpdateRes = {
  msgId: string;
};

export const updateJob = async (job: string, update: JobUpdateReq) => {
  const url = `/Job/Update/${encodeURIComponent(job)}`;

  if (update.causeCode && !update.causeCode.comments.startsWith("OS_")) {
    update.causeCode.comments = "OS_" + update.causeCode.comments;
  }

  if (update.etr && !update.etr.comments.startsWith("OS_")) {
    update.etr.comments = "OS_" + update.etr.comments;
  }

  if (
    update.crewAssignmentStatus &&
    !update.crewAssignmentStatus.comments.startsWith("OS_")
  ) {
    update.crewAssignmentStatus.comments =
      "OS_" + update.crewAssignmentStatus.comments;
  }

  if (update.jobStatus && !update.jobStatus.comments.startsWith("OS_")) {
    update.jobStatus.comments = "OS_" + update.jobStatus.comments;
  }

  if (update.jobType && !update.jobType.comments.startsWith("OS_")) {
    update.jobType.comments = "OS_" + update.jobType.comments;
  }

  if (update.jobSubType && !update.jobSubType.comments.startsWith("OS_")) {
    update.jobSubType.comments = "OS_" + update.jobSubType.comments;
  }

  if (update.jobComments && !update.jobComments.startsWith("OS_")) {
    update.jobComments = "OS_" + update.jobComments;
  }

  const res = await http.patch<JobUpdateRes>(url, update, config);
  return res;
};

export type CrewStatus = {
  employeeId: string;
  crewId: string;
  phoneNumber: string;
  name: string;
  crewStatus: string;
};

export const getCrewStatus = async (crewId: string) => {
  const url = `/Crew/${encodeURIComponent(crewId)}/Status`;

  const res = await http.get<CrewStatus>(url, config);
  return res;
};

export type HistoricJob = {
  premiseId: string;
  jobId: string;
  jobDisplayId: string;
  aor: string;
  jobSubType: string;
  ert: string;
  region: string;
  prnFlag: string;
  comments: Comments;
  xCord: string;
  yCord: string;
  causeCode: string;
  jobStatus: string;
  jobType: string;
  outageDate: Date;
  RestorationDate: Date;
  circuit: string;
};

export const getJobHistoryByPremiseId = async (premiseId: string) => {
  const url = `/JobHistory`;

  const res = await http.get<HistoricJob[]>(
    url,
    {
      ...config,
      params: { premiseId: premiseId },
    },
    false
  );
  return res;
};

export type LongLatRes = {
  geometries: [Geo | GeoErr];
};

type Geo = {
  x: number;
  y: number;
};

type GeoErr = {
  code: number;
  message: string;
  details: string[];
};

export const getLongLat = async (x: number, y: number) => {
  const url = `${process.env.REACT_APP_API_URL}/esri/services/Utilities/Geometry/GeometryServer/project`;
  const params = {
    inSR: 102121,
    outSR: 4152,
    transformForward: false,
    f: "json",
    geometries: `${x},${y}`,
  };
  const res = await http.get<LongLatRes>(url, {
    params,
    ...headers,
  });
  return res;
};

const AMI_URL = `${process.env.REACT_APP_API_URL}/ami-adms`;

export type AMIJobStatus = {
  jobId: string;
  affected: number;
  restored: number;
  powerOut: number;
  dontKnow: number;
};

// export const getAMIJobStatus = async (jobId: string) => {
//   const url = `${AMI_URL}/JobStatus/${jobId}`;
//   return await http.get<AMIJobStatus>(url, config);
// };

export type AMICustomerStatus = {
  jobId: string;
  affected: number;
  restored: number;
  powerOut: number;
  dontKnow: number;
};

export const getAMICustomerStatus = async (customerNumber: string) => {
  const url = `${AMI_URL}/CustomerStatus/${customerNumber}`;
  return await http.get<AMICustomerStatus>(url, config);
};

export type AMISystemStatus = {
  power_On: number;
  power_Off: number;
  unknown: number;
};

export const getAMISystemStatus = async () => {
  const url = `${AMI_URL}/SystemStatus/`;
  return await http.get<AMISystemStatus>(url, config);
};
